import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useToggle } from 'helpers/hooks/useToggle';
import { useAnalyticsContext } from '@pocketoutdoormedia/analytics-provider';
import { useClickAway } from 'react-use';
import { useHandleKeyDown } from 'helpers/hooks/useHandleKeyDown';
import { useTailwindBreakpoint } from 'helpers/hooks/useTailwindBreakpoint';
import debounce from 'lodash/debounce';

import type { NavLinkProps } from '../types';

import HeaderNavLink from './HeaderNavLink';
import NavigationDropdown from './NavigationDropdown';

type Props = {
  currentPath: string;
  exactPath: string;
  isNavHidden: boolean;
  link: NavLinkProps;
};

const NavigationListItem = ({
  currentPath,
  exactPath,
  isNavHidden,
  link
}: Props) => {
  const listItemRef = useRef(null);
  const [isDropdownOpen, { setFalse: closeDropdown, toggle: toggleDropdown }] =
    useToggle(false);
  const [isDropdownOverflow, setIsDropdownOverflow] = useState(false);
  const { elementClicked } = useAnalyticsContext();

  const handleNavLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (link.subElements) toggleDropdown();
    elementClicked({
      name: 'main-navigation',
      type: 'link',
      url: `${window.location.origin}${event.currentTarget.pathname}`
    });
  };

  useClickAway(listItemRef, closeDropdown);

  const handleOnEscapeDown = useHandleKeyDown('Escape', closeDropdown);

  useEffect(() => {
    if (isNavHidden) closeDropdown();
  }, [closeDropdown, isNavHidden]);

  const { isScreenNarrowerThan } = useTailwindBreakpoint();
  const isMobile = isScreenNarrowerThan('narrow');
  const dropdownWidth = isMobile ? 300 : 400;

  useEffect(() => {
    const handleOverlapping = debounce(() => {
      if (listItemRef?.current) {
        const { left, right } = listItemRef.current.getBoundingClientRect();
        const listItemCenter = (left + right) / 2;

        // check dropdown right edge position assuming the dropdown is centered
        const potentialDropdownRightEdge = listItemCenter + dropdownWidth / 2;

        setIsDropdownOverflow(
          document.body.clientWidth - potentialDropdownRightEdge < 0
        );
      }
    }, 100);

    handleOverlapping();

    window.addEventListener('resize', handleOverlapping);

    return () => {
      window.removeEventListener('resize', handleOverlapping);
    };
  }, [dropdownWidth, isMobile]);

  return (
    <li ref={listItemRef} className="inline-block relative">
      <HeaderNavLink
        handleNavLinkClick={handleNavLinkClick}
        hasSubmenu={!!link?.subElements}
        isActive={link.matchers.some((matcher) => matcher === currentPath)}
        link={link}
      >
        <span className="relative h-full flex items-center whitespace-nowrap">
          {link.text}
        </span>
      </HeaderNavLink>
      {link.subElements ? (
        <div
          className={clsx('absolute transition-opacity duration-300', {
            'invisible opacity-0': !isDropdownOpen,
            'left-1/2 -translate-x-1/2': !isDropdownOverflow,
            'right-0': isDropdownOverflow,
            'visible opacity-100': isDropdownOpen
          })}
          onKeyDown={handleOnEscapeDown}
          role="none"
          style={{ width: dropdownWidth }}
        >
          <NavigationDropdown
            exactPath={exactPath}
            handleNavLinkClick={handleNavLinkClick}
            link={link}
          />
        </div>
      ) : null}
    </li>
  );
};

export default NavigationListItem;

import { useAnalyticsContext } from '@pocketoutdoormedia/analytics-provider';
import { useEffect } from 'react';
import { noop } from 'helpers/utils/noop';

import { useSSR } from './useSSR';

// watch-regwall
export const useRegisterWatchRegwallAnalytics = () => {
  const {
    elementClicked,
    registrationErrorsReceived,
    regwallViewed,
    userSignedIn,
    userSignedUp
  } = useAnalyticsContext();
  const { isBrowser } = useSSR();

  useEffect(() => {
    if (!isBrowser) return noop;

    const handleEvents = (event: MessageEvent) => {
      if (event?.data?.type === 'rpm-event') {
        const { payload } = event.data;
        switch (payload.action) {
          case 'ELEMENT_CLICKED': {
            const trackingPayload = {
              type: 'button',
              url: window.location.href
            };

            elementClicked(trackingPayload);
            break;
          }
          case 'REGISTER_ERROR': {
            const trackingPayload = {
              error_messages: payload.error_messages,
              step_name: 'Registration',
              step_number: 3
            };

            registrationErrorsReceived(trackingPayload);
            break;
          }
          case 'SIGNED_IN': {
            const trackingPayload = {
              registration_url: window.location.href
            };

            userSignedIn(trackingPayload);
            break;
          }
          case 'SIGNED_UP': {
            const trackingPayload = {
              registration_url: window.location.href
            };

            userSignedUp(trackingPayload);
            break;
          }
          case 'VIEWED': {
            const trackingPayload = {
              type: 'reg-wall'
            };

            regwallViewed(trackingPayload);
            break;
          }
          default:
            break;
        }
      }
    };

    window.addEventListener('message', handleEvents);

    return () => window.removeEventListener('message', handleEvents);
  }, [
    elementClicked,
    isBrowser,
    registrationErrorsReceived,
    regwallViewed,
    userSignedIn,
    userSignedUp
  ]);
};

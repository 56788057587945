import { NavigationLink } from 'components/02-molecules/NavigationLink';
import clsx from 'clsx';

import type { NavLinkProps } from '../types';

type Props = {
  exactPath: string;
  handleNavLinkClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  link: NavLinkProps;
};

const NavigationDropdown = ({ exactPath, handleNavLinkClick, link }: Props) => (
  <div className="py-2 grid grid-cols-1 rounded-lg bg-calendar-gray shadow-theme-shadow-dropdown">
    {link.subElements.map((subElement) => {
      const isActive = subElement.matchers.some(
        (matcher) => matcher === exactPath
      );
      return (
        <NavigationLink
          key={subElement.text}
          classes={clsx(
            'text-12 narrow:text-18 px-6 py-4 text-left hover:text-btn-secondary-hover active:text-btn-secondary-active px-4 hover:bg-new-neutral-900 font-plus-jakarta-sans focus-visible:bg-new-neutral-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus',
            {
              'text-btn-secondary-hover': isActive,
              'text-network-white': !isActive
            }
          )}
          href={subElement.href}
          label={subElement.text}
          onClick={(event) => handleNavLinkClick(event)}
        >
          {subElement.text}
        </NavigationLink>
      );
    })}
  </div>
);

export default NavigationDropdown;

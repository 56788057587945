export const SITE_NAME = 'Outside Watch';
export const ANALYTICS_DOMAIN = 'watch';
export const APPLICATION_URL = 'https://watch.outsideonline.com';
export const ANONYMOUS_USER_ID = 'fb95b2ad-6e3a-438b-ac44-e8b238eba3ea';
export const SCOUT_APP_URL = 'https://scout.outsideonline.com';
export const PLACEHOLDER_POSTER_SRC = '/placeholder4_3.png';
export const PLACEHOLDER_THUMBNAIL_SRC = '/placeholder16_9.png';
export const LIVE_STREAM_24_ID = 'live-stream-24';

export const OUTSIDE_ONLINE_BASE_URL =
  process.env.NEXT_PUBLIC_OUTSIDE_ONLINE_BASE_URL ||
  'https://www.outsideonline.com';

export const OUTSIDE_INC_URLS = {
  HELP: 'https://help.outsideinc.com/hc/en-us',
  PRIVACY_POLICY: 'https://www.outsideinc.com/privacy-policy/',
  TERMS_AND_CONDITIONS: 'https://www.outsideinc.com/terms-of-use/'
};

export const ACCOUNTS_OUTSIDE_ONLINE_BASE_URL =
  process.env.NEXT_PUBLIC_ACCOUNTS_BASE_URL ||
  'https://accounts.outsideonline.com/oidc-frontend';

export const ACCOUNTS_OUTSIDE_ONLINE_URLS = {
  BOOKMARKS: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/bookmarks`,
  OUTSIDE_PLUS: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/purchase-membership`, // This should be used with redirectUrl search param
  PASSWORD_RESET: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/forgot-password`,
  PROFILE: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/profile`,
  PURCHASE: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/purchase-membership`,
  SETTINGS: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/settings`,
  SIGN_UP: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/signup`,
  SUBSCRIPTION: `${ACCOUNTS_OUTSIDE_ONLINE_BASE_URL}/subscription`
} as const;
